import React, { useEffect, useState } from "react";
import "./usercreation.css";
import { useSelector } from "react-redux";
import { getData, putData } from "../../../services/data";
import { setEvents } from "../../../store/slices/event";
import { showSuccess } from "../../../helpers/toast";
import Select from "react-select";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

const UserEditForm = ({ userData, onClose }) => {
  const users = useSelector((state) => state.userAdmin.data);
  const [userDetails, setUserDetails] = useState({});
  const [selectedEvent, setSelectedEvent] = useState([]);
  const [processingAJAX, setProcessingAJAX] = useState(false);
  const [isActive, setisActive] = useState(false);
  const [formData, setFormData] = useState({
    firstName: userData.first_name,
    lastName: userData.last_name,
    email: userData.email,
    phoneNumber: userData.phone,
    userType: "",
    event: userData.event,
    department: userData.department,
    address: userData.address,
    checkboxes: {
      create_events: false,
      cash_vendor: false,
      scan_tickets: false,
      live_feeds: false,
      reserve_tickets: false,
      finance: false,
    },
    createEvent: false,
    numberOfEvents: "",
    timeLimit: "",
  });

  const events = useSelector((state) => state.event.data);

  const handleChangeEvent = (selectedEvent) => {
    setSelectedEvent(selectedEvent);
  };

  useEffect(() => {
    getData("events/admin", setEvents);
    getData("users/get_user_update/" + userData.id).then((res) => {
      setUserDetails(res.data);
      setSelectedEvent(res.data.events);
      setisActive(res?.data?.user?.active);
      setFormData({
        ...formData,
        checkboxes: {
          ...formData.checkboxes,
          admin: !!res.data.roles.map((role) => role.name).includes("admin"),
          create_events: !!res.data.roles
            .map((role) => role.name)
            .includes("create_events"),
          cash_vendor: !!res.data.roles
            .map((role) => role.name)
            .includes("cash_vendor"),
          scan_tickets: !!res.data.roles
            .map((role) => role.name)
            .includes("scan_tickets"),
          live_feeds: !!res.data.roles
            .map((role) => role.name)
            .includes("live_feeds"),
          reserve_tickets: !!res.data.roles
            .map((role) => role.name)
            .includes("reserve_tickets"),
          finance: !!res.data.roles
            .map((role) => role.name)
            .includes("finance"),
          organizer: !!res.data.roles
            .map((role) => role.name)
            .includes("organizer"),
          viewer: !!res.data.roles.map((role) => role.name).includes("viewer"),
        },
      });
    });
  }, []);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    setFormData((prevData) => ({
      ...prevData,
      [name]:
        type === "checkbox" ? { ...prevData[name], [value]: checked } : value,
    }));
  };

  const handleFormSubmit = () => {
    console.log("formData.phoneNumber.startsWith",formData.phoneNumber.startsWith("+"),formData.phoneNumber);
    const phoneNumber = formData.phoneNumber.startsWith("+") ? formData.phoneNumber.substring(1) : formData.phoneNumber;
    setProcessingAJAX(true);
    putData("users/update_user_profile", undefined, {
      id: userData.id,
      first_name: formData.firstName,
      last_name: formData.lastName,
      active: `${isActive}`,
      email: formData.email,
      // phone: formData.phoneNumber.substring(1),
      phone: phoneNumber,
      department: formData.orgDepartment,
      address: formData.address,
      // events: Array.isArray(formData.selectEvent) ? formData.selectEvent.join(',') : formData.selectEvent,
      events: selectedEvent.map((a) => a.id).toString(),
      roles: Object.keys(formData.checkboxes)
        .filter((k) => formData.checkboxes[k])
        .join(","),
    })
      .then((res) => {
        setProcessingAJAX(false);
        if (res.status === 200) {
          showSuccess(res.data.message ?? "User edited successfully");
          onClose();
          setisActive(false);
          setFormData({
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            userType: "",
            event: "",
            department: "",
            address: "",
            checkboxes: {
              add: false,
              buyTicket: false,
              scanTicket: false,
              liveFeed: false,
              print: false,
              reserve: false,
              finance: false,
            },
            createEvent: false,
            numberOfEvents: "",
            timeLimit: "",
          });
        }
      })
      .catch((error) => {
        setProcessingAJAX(false);
      });
  };

  return (
    <div>
      <div className="add__user_main_wrap">
        <h2 className="add__user_title_heading">Edit User</h2>
        <form className="add__user_form_field_form">
          <div className="add__user_form_field">
            <label>First Name</label>
            <input
              type="text"
              name="firstName"
              value={formData.firstName}
              onChange={handleInputChange}
            />
          </div>
          <div className="add__user_form_field">
            <label>Last Name</label>
            <input
              type="text"
              name="lastName"
              value={formData.lastName}
              onChange={handleInputChange}
            />
          </div>
          <div className="add__user_form_field">
            <label>Email</label>
            <input
              type="text"
              name="email"
              disabled={false}
              value={formData.email}
              onChange={handleInputChange}
            />
          </div>
          <div className="add__user_form_field">
            <label>Phone Number</label>
            {/* <input
              type="text"
              name="phoneNumber"
              disabled={false}
              value={formData.phoneNumber}
              onChange={handleInputChange}
            /> */}
            <PhoneInput
              initialValueFormat="national"
              international
              defaultCountry="BS"
              className="w-100"
              // placeholder="Enter Phone Number"
              value={formData.phoneNumber}
              onChange={(value) =>
                handleInputChange({
                  target: {
                    name: "phoneNumber",
                    value,
                  },
                })
              }
            />
          </div>
          {/* <div className="add__user_form_field">
            <label>Select Event</label>
            <select
              className="event form-select"
              name="event"
              value={formData.event}
              onChange={handleInputChange}
            >
              { }
              <option value="">Event</option>
            </select>
          </div> */}
          <div className="add__user_form_field">
            <label>Department</label>
            <select
              className="department form-select"
              name="department"
              value={formData.department}
              onChange={handleInputChange}
            >
              <option value="">Department</option>
            </select>
          </div>
          <div className="add__user_form_field">
            <label>Location</label>
            <input
              type="text"
              name="address"
              value={formData.address}
              onChange={handleInputChange}
            />
          </div>
          <div className="add__user_form_field">
            <div className="event__creation_field_wrap select_speaker_field w-100">
              <label className="mb-4">Select Event </label>
              <Select
                isMulti
                options={events}
                getOptionLabel={(s) => s.name}
                getOptionValue={(s) => s.id}
                value={selectedEvent}
                onChange={handleChangeEvent}
                className="select_speaker_field_drop form-select"
              />
            </div>
          </div>
          <div className="add__user_form_field">
            <label className="mb-3">Status</label>
            <label style={{ display: "flex", gap: 5 }}>
              <input
                type="checkbox"
                checked={isActive}
                onChange={() => setisActive(!isActive)}
              />
              Active
            </label>
          </div>
          <div className="add__user_title_heading">Role Assign</div>
          <div className="add__user_form_field_checkbox">
            {Object.keys(formData.checkboxes).map((checkbox) => (
              <label key={checkbox} className="checkbox-label">
                <input
                  type="checkbox"
                  className={`checkbox-${checkbox}`}
                  name="checkboxes"
                  value={checkbox}
                  checked={formData.checkboxes[checkbox]}
                  onChange={handleInputChange}
                />
                {checkbox.replace("_", " ")}
              </label>
            ))}
          </div>
          <div className="add__user_form_field_checkbox_listing">
            {formData.createEvent && (
              <div className="add__user_form_field_checkbox_condition">
                <input
                  type="text"
                  className="number-of-events"
                  name="numberOfEvents"
                  placeholder="Number of Events"
                  value={formData.numberOfEvents}
                  onChange={handleInputChange}
                />
                <select
                  className="time-limit form-select"
                  name="timeLimit"
                  value={formData.timeLimit}
                  onChange={handleInputChange}
                >
                  <option value="">Time Limit</option>
                  {/* Add time limit options here */}
                </select>
              </div>
            )}
          </div>
          <div className="add__user_form_field_button">
            <button
              type="button"
              onClick={handleFormSubmit}
              className={`add__user_form_field_button_add_user ${
                processingAJAX ? "disbaledButton" : ""
              }`}
            >
              {processingAJAX ? "Updating User" : "Update User"}
            </button>
            <button
              type="button"
              onClick={onClose}
              className="add__user_form_field_button_cancel"
            >
              Cancel
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default UserEditForm;
